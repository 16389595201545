import Backbone from 'lib/backbone';
import invokeCallbacks from 'app/NVTagCallbacks';
import DoubleBracket from 'app/DoubleBracket';
import FieldsetView from 'app/views/FieldsetView';
import FastAction from 'app/FastAction';
import currency from 'app/views/helpers/currency';
import assetUrl from 'app/assetUrl';
import cookieConsentConfig from 'app/CookieConsentConfiguration';
import eaStripe from 'app/eaStripe';

var _ = Backbone._;
var $ = Backbone.$;

export default FieldsetView.extend({
  __name__: 'PaymentInformationView',
  readonly: false,
  events: {
    'change': 'renderFeedback',
    'change *': 'touch',
    'change input[name="PaymentMethod"]': 'changePaymentMethodEventHandler',
    'click .change-account': 'changeAccount',
    'payment.cardType': 'setCardType',
    'focus .eft-input': 'eftInputFocus',
    'blur .eft-input': 'eftInputFocus',
    'click .at-whatsthis': 'openEftWhatsThisModal',
    'keypress .at-whatsthis': 'openEftWhatsThisModal'
  },
  accepted: {
    visa: { index: 0, name: 'Visa' },
    dinersclub: { index: 1, name: 'Diners Club' },
    mastercard: { index: 2, name: 'Master Card' },
    amex: { index: 3, name: 'American Express' },
    discover: { index: 4, name: 'Discover' }
  },
  initialize: function () {
    FieldsetView.prototype.initialize.call(this);
    _.bindAll(this, 'changeReadonly', 'amountChanged', 'recurrenceChanged');
    var byCardIndex = _.bind(function (a, b) {
      return this.accepted[a].index - this.accepted[b].index;
    }, this);

    this.cards = this.options.accepted_cards.slice(0).sort(byCardIndex);

    this.cardType = 'unknown';
    this.originalSubmitLabel = this.options.parent.context().submit_label;
    this.contributionAmountModel = this.options.formview.contributionAmountModel;
    this.contributionRecurrenceModel = this.options.formview.contributionRecurrenceModel;
    this.PayPalCommercePlatformInstance = this.options.formview.PayPalCommercePlatformInstance;

    // In the case of ticketed event forms, this fieldset isn't required and should be hidden when the amount is non-zero
    this.hideForNoAmount = this.options.formview.options.form_definition.type === 'EventForm';
    this.hide = this.hideForNoAmount && (this.contributionAmountModel.get('totalAmount') <= 0);

    this.listenTo(this.options.formview, 'paymentMethodChanged', this.changePaymentMethod);
    this.listenTo(this.options.formview, 'paypalAuthorized', this.setPayPalDisplay);
    this.listenTo(this.options.formview, 'applePayAuthorized', this.setApplePayDisplay);
    this.listenTo(this.options.formview, 'stripeApplePayAuthorized', this.setApplePayDisplay);
    this.listenTo(this.options.formview, 'googlePayAuthorized', this.setGooglePayDisplay);
    this.listenTo(cookieConsentConfig, 'change:functionalAccepted', this.adjustCheckbox);
    this.contributionAmountModel.on('change:totalAmount', this.amountChanged);
    this.contributionRecurrenceModel.on('change', this.recurrenceChanged);

    this.applepayAuthorizedAmount = null;
    this.applePayAuthorizedFrequency = null;
    this.googlePayAuthorizedAmount = null;
    this.googlePayAuthorizedFrequency = null;
    this.paypalAuthorizedRecurring = null;
    this.paypalAuthorizedAmount = null;
    this.paypalFundingSource = null;
    this.paypalLogoUrl = assetUrl('images/paypal-logo.png');
    this.venmoLogoUrl = assetUrl('images/venmo-logo.svg');

    var isFormBaseUrlOberon = this.options.formview.options.form_definition.url.base.includes('myngp');
    this.savedCardRequiresSecurityCode = isFormBaseUrlOberon;
  },
  adjustCheckbox: function () {
    FastAction.adjustCheckbox(this);
  },
  recurrenceChanged: function () {
    // When the recurring flag is toggled,
    // If Paypal is authorized for a single payment only, we'll need to
    // tell the user to reauthorize if they've upgraded to recurring.
    if (this.isPayPalAuthorized() && (this.contributionRecurrenceModel.hasChanged('isRecurring') || this.options.formview.paymentMethodConfiguration.payPalIntegrationType === 'PayPalCommercePlatform')) {
      this.renderFeedback();
    }

    // Capture recurrence changes to update the Stripe element mode appropriately
    var displayName = this.contributionRecurrenceModel.getFrequencyDisplayName() + ' ' + this.resources.PrimaryResources.Contribution;
    eaStripe.updateStripeElementRecurrence(this.contributionRecurrenceModel.get('frequency'), displayName);

    this.updateSubmitText();
  },
  amountChanged: function () {
    var newAmount = this.contributionAmountModel.get('totalAmount');
    var previousAmount = this.contributionAmountModel.previous('totalAmount');

    if (this.hideForNoAmount) {
      // Don't re-render every time the amount changes, just when toggling is necessary
      var paymentRequired = newAmount > 0;
      var shouldResetVisibility = !previousAmount || (paymentRequired !== (previousAmount > 0));
      if (shouldResetVisibility) {
        this.options.definition.required = paymentRequired;
        this.hide = !paymentRequired;

        // Re-render, then manually set correct display properties
        this.dispose();
        this.render();

        if (this.previousVal) {
          this.setval(this.previousVal);
        }
      }
    }

    if (this.isPayPalAuthorized()) {
      this.clearFeedback();
      this.renderFeedback();
      if (this.options.formview.paymentMethodConfiguration.payPalIntegrationType === 'PayPalCommercePlatform') {
        var formUrl = this.options.formview.options.form_definition.url.base + this.options.formview.options.form_definition.url.full.split('/')[5];
        this.PayPalCommercePlatformInstance.updateOrder(formUrl, newAmount);
      }
      this.$('.at-paypal-amount').text(currency.format(newAmount));
    }

    // If Apple Pay has been authorized, update feedback for amount being the same as the authorized amount
    if (this.isApplePayAuthorized() || this.isStripeApplePayAuthorized() || this.isGooglePayAuthorized()) {
      this.renderFeedback();
    }

    // Capture amount changes to update the Stripe element to show the correct amount for re-authorization
    eaStripe.updateStripeElementAmount(newAmount);

    this.updateSubmitText();

  },
  isApplePayAuthorized: function () {
    return this.options.formview.paymentMethodConfiguration.isParagonApplePayEnabled && $('input[name="paymentResponseDetails"]').val();
  },
  isStripeApplePayAuthorized: function () {
    return this.options.formview.paymentMethodConfiguration.isStripeApplePayEnabled && $('input[name="paymentResponseDetails"]').val();
  },
  isGooglePayAuthorized: function () {
    return this.options.formview.paymentMethodConfiguration.isGooglePayEnabled && $('input[name="confirmationTokenId"]').val();
  },
  isPayPalAuthorized: function () {
    return this.options.formview.paymentMethodConfiguration.isPayPalEnabled && $('input[name="paypalNonce"]').val();
  },
  changeAccount: function (e) {
    e.preventDefault();

    if (this.selectedPaymentMethod === 'creditcard') {
      this.changeReadonly(false);
      this.subviews.Account.focus();
    }

    return this;
  },
  changeReadonly: function (readonlyState, cc_4_digit, cc_type, fastActionTokenPool) {
    if (_.isBoolean(readonlyState)) {
      if (cc_type) {
        this.cc_type = cc_type.toLowerCase();
      }

      if (cc_4_digit) {
        this.cc_4_digit = cc_4_digit;
      }

      if (readonlyState) {
        this.fastActionTokenPool = fastActionTokenPool;
      } else {
        delete this.fastActionTokenPool;
      }

      if (readonlyState === this.readonly && !cc_4_digit) {
        return this;
      }

      if (_.indexOf(this.cards, this.cc_type) === -1) {
        readonlyState = false;
      }

      this.readonly = !this.options.isKioskEnabled && readonlyState && !this.options.disableCreditCardAutofill;
      this.def.required = !this.hide && !this.readonly;

      this.dispose();

      return this.render();
    }
    return this;
  },
  getAmount: function () {
    var formviewValues = this.options.formview.val();
    if (!formviewValues) {
      return 0;
    }
    return parseFloat(formviewValues.Amount || 0) + parseFloat(formviewValues.CoverCostsAmount || 0);
  },
  getAvailablePaymentMethods: function () {
    var isRecurring = this.contributionRecurrenceModel.get('isRecurring');
    // Filter out apple pay if recurring is enabled
    return _.filter(this.options.formview.paymentMethodConfiguration.acceptedPaymentMethods, function (method) {
      return method !== 'applepay' || !isRecurring;
    });
  },
  readonlyCards: function () {
    return _.map(this.cards, function (name) {
      return { 'name': name, 'state': (name !== this.cc_type ? 'off' : '') };
    }, this);
  },
  openEftWhatsThisModal: function (e) {
    if (e.type === 'click' || e.keyCode === 13) {
      window.open(assetUrl('bankhelp.html'),
        'efthelp', 'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width=400,height=450');
    }
  },
  dispose: function () {
    // dispose subviews
    _.forOwn(this.subviews, function (subview) {
      if (subview && _.isFunction(subview.dispose)) {
        subview.dispose();
      }
    });
  },
  getCreditCardValidationViews: function () {
    // If the section is required but we're not using a saved card, then return all subviews
    var shouldValidateSubviews = (!('required' in this.def) || this.def.required) && !this.readonly;
    if (shouldValidateSubviews) {
      return this.subviews;
    }

    // Otherwise, if we're using a saved card but the security code is required, return only that
    var shouldValidateSecurityCode = this.readonly && this.isFormBaseUrlOberon && this.subviews.SecurityCode && this.subviews.SecurityCode.def.required;
    if (shouldValidateSecurityCode) {
      return [this.subviews.SecurityCode];
    }

    // If neither of those cases apply, there is nothing more to validate
    return [];
  },
  errors: function () {
    if (this.hide) {
      return [];
    }

    var errors = [];

    switch (this.selectedPaymentMethod) {
      case 'paypal':
      case 'venmo':
        errors = this.errorsForPayPal();
        break;
      case 'eft':
        errors = this.errorsForEft();
        break;
      case 'applepay':
        errors = this.errorsForApplePay();
        break;
      case 'googlepay':
        errors = this.errorsForGooglePay();
        break;
      default:
        errors = _(this.getCreditCardValidationViews()).chain().invoke('errors').flatten().compact().value();
    }

    return invokeCallbacks('alterError', {
      val: this.val(),
      field_name: this.field_name(),
      errors: errors,
      def: this.def
    }).errors;
  },
  renderFeedbackWithErrors: function (errors) {
    if (!errors || !errors.length) {
      this.clearFeedback();
      return errors;
    }

    if (this.selectedPaymentMethod === 'paypal' || this.selectedPaymentMethod === 'venmo') {
      this.$('.paypal-label').addClass('error');
      this.$('.paypal-label').find('small.' + 'error').remove();
      this.$('.paypal-label').append(this.error_template({ 'error_text': errors[0], 'behavior_classes': 'error' }));
    } else if (this.selectedPaymentMethod === 'applepay') {
      this.$('.apple-pay-label').addClass('error');
      this.$('.apple-pay-label').find('small.' + 'error').remove();
      this.$('.apple-pay-label').append(this.error_template({ 'error_text': errors[0], 'behavior_classes': 'error' }));
    } else if (this.selectedPaymentMethod === 'googlepay') {
      this.$('.google-pay-label').addClass('error');
      this.$('.google-pay-label').find('small.' + 'error').remove();
      this.$('.google-pay-label').append(this.error_template({ 'error_text': errors[0], 'behavior_classes': 'error' }));
    } else if (this.selectedPaymentMethod === 'eft') {
      // This convoluted logic is an attempt to recreate the same validation rules provided by the textboxview for EFT inputs.
      // The either the errors should show one at a time as inputs are focused or
      // all of the errors should show if the submit button is focused or if render is called and nothing has been focused
      this.clearFeedback();
      var forceErrors = this.eftSubmitFocused || _.isEmpty(this.eftInputsFocused);
      for (var i = 0; i < errors.length; i++) {
        if ((forceErrors || this.eftInputsFocused.routingNumber) && errors[i].errorId === this.eftErrorIds.routing) {
          this.$('.routing-number-label').addClass('error');
          this.$('.routing-number-label').find('small.' + 'error').remove();
          this.$('.routing-number-label').append(this.error_template({
            'error_text': errors[i],
            'behavior_classes': 'error'
          }));

          continue;
        }

        if ((forceErrors || this.eftInputsFocused.bankAccountNumber) && errors[i].errorId === this.eftErrorIds.account) {
          this.$('.account-number-label').addClass('error');
          this.$('.account-number-label').find('small.' + 'error').remove();
          this.$('.account-number-label').append(this.error_template({
            'error_text': errors[i],
            'behavior_classes': 'error'
          }));

          continue;
        }

        if ((forceErrors || this.eftInputsFocused.confirmBankAccountNumber) && errors[i].errorId === this.eftErrorIds.accountConfirm) {
          this.$('.confirm-account-number-label').addClass('error');
          this.$('.confirm-account-number-label').find('small.' + 'error').remove();
          this.$('.confirm-account-number-label').append(this.error_template({
            'error_text': errors[i],
            'behavior_classes': 'error'
          }));

          continue;
        }

        if ((forceErrors || this.eftInputsFocused.authorizePayment) && errors[i].errorId === this.eftErrorIds.authorize) {
          this.$('.authorize-payment-label').addClass('error');
        }
      }
    }
    return errors;
  },
  renderFeedback: function () {

    var errors = this.errors();
    if (!errors || !errors.length) {
      this.clearFeedback();
      return [];
    }

    switch (this.selectedPaymentMethod) {
      case 'paypal':
      case 'venmo':
      case 'eft':
      case 'applepay':
      case 'googlepay':
        return this.renderFeedbackWithErrors(errors);
      default:
        return _(this.getCreditCardValidationViews()).chain().invoke('renderFeedback').flatten().compact().value();
    }
  },
  render: function () {
    var config = this.options.formview.paymentMethodConfiguration;
    var googlePayMarkUrl = assetUrl('images/googlepay-mark.svg');

    var context = {
      title: this.title,
      resources: this.resources,
      ccEnabled: config.isCcEnabled,
      paypalEnabled: config.isPayPalEnabled,
      eftEnabled: config.isEftEnabled,
      eftLegalDisclaimer: config.eftLegalDisclaimer,
      paragonApplePayEnabled: config.isParagonApplePayEnabled,
      stripeApplePayEnabled: config.isStripeApplePayEnabled,
      googlePayEnabled: config.isGooglePayEnabled,
      googlePayMarkUrl: googlePayMarkUrl,
      showPaymentMethods: !config.shouldFillFromPaymentResponse,
      paypalLogoUrl: this.paypalLogoUrl,
      isReadonly: !!this.readonly,
      savedCardRequiresSecurityCode: this.savedCardRequiresSecurityCode,
      stripeJsEnabled: config.isStripeJsEnabled
    };

    if (!this.options.isKioskEnabled && this.readonly) {
      context.cc_4_digit = this.cc_4_digit;
      context.labelhide = this.options.labels === 'inline';

      _.each(this.def.children, function (element, index) {
        if (element.name === 'SecurityCode') {
          context.cvv = { type: 'cvv2', index: index };
        } else if (element.name === 'UpdateMyProfile') {
          context.fa = { type: element.type, index: index };
        }
      });
    } else {
      // Possibly make cards dynamic, if Oberon ever allows
      // that to be changed
      context.cards = this.cards.slice();
      context.children = [];

      var expirationElement = null;
      var expirationIndex = -1;

      _.each(this.def.children, function (element, index) {
        if (element.name === 'Account') {
          context.children.push({ type: 'credit_card', index: index, name: element.name });
        } else if (element.name === 'SecurityCode') {
          context.children.push({ type: 'cvv2', index: index, name: element.name });
        } else if (/^Expiration/.test(element.name)) {
          //Use the first styled Expiration element to support clients styling either
          if (!expirationElement || (expirationElement && !expirationElement.styles && element.styles)) {
            expirationElement = element;
            expirationIndex = index;
          }
        } else {
          context.children.push({ type: element.type, index: index, name: element.name });
        }
      });

      if (expirationElement && expirationIndex >= 0) {
        context.children.push({ type: 'cc_expiration', index: expirationIndex, name: expirationElement.name });
      }

      var rows = [['Account', 'ExpirationMonth', 'ExpirationYear', 'ExpirationDate', 'SecurityCode']];
      var child = _.indexBy(context.children, 'name');
      context.rows = _.map(rows, function (row) {
        return _.reduce(row, function (memo, name) {
          if (child[name]) {
            memo.push(child[name]);
            child[name].inRow = true;
          }
          return memo;
        }, []);
      });
      var extra = _.reject(child, { inRow: true });
      if (extra.length) {
        context.rows.push(extra);
      }
    }

    if (this.paypalNonce) {
      context.paypalNonce = this.paypalNonce;
      context.payPalNonceType = this.payPalNonceType;
      context.amount = this.contributionAmountModel.get('totalAmount');
      context.paypalAccount = this.paypalAccount;
    }

    context = invokeCallbacks('alterContext', { element: this.type, context: context, def: this.def }).context;
    this.$el.html(this.template(context));

    if (this.selectedPaymentMethod) {
      this.setPaymentInformationDisplay(this.selectedPaymentMethod);
    }

    return this.hideOrShow();
  },
  remove: function () {
    Backbone.View.prototype.remove.call(this);
  },
  errorsForEft: function () {
    var errors = [];
    var integerRegex = /^\d+$/;
    if (this.selectedPaymentMethod === 'eft') {
      if (this.$('#routingNumber').val().length < 9) {
        errors.push(this.toError(this.resources.PrimaryResources.RoutingNumberMustBeNineNumbers, this.eftErrorIds.routing));
      } else if (!integerRegex.test(this.$('#routingNumber').val())) {
        errors.push(this.toError(this.resources.PrimaryResources.RoutingNumberMustBeAllNumbers, this.eftErrorIds.routing));
      }

      if (this.$('#bankAccountNumber').val().length === 0) {
        errors.push(this.toError(this.resources.PrimaryResources.AccountNumberRequired, this.eftErrorIds.account));
      } else if (!integerRegex.test(this.$('#bankAccountNumber').val())) {
        errors.push(this.toError(this.resources.PrimaryResources.AccountNumberMustBeAllNumbers, this.eftErrorIds.account));
      }

      if (this.$('#confirmBankAccountNumber').val().length === 0) {
        errors.push(this.toError(this.resources.PrimaryResources.ConfirmAccountNumberRequired, this.eftErrorIds.accountConfirm));
      } else if (this.$('#confirmBankAccountNumber').val() !== this.$('#bankAccountNumber').val()) {
        errors.push(this.toError(this.resources.PrimaryResources.AccountNumbersDoNotMatch, this.eftErrorIds.accountConfirm));
      }

      if (!this.$('#authorizePayment').is(':checked')) {
        errors.push(this.toError('Must authorize payment' /* Error text not used */, this.eftErrorIds.authorize));
      }
    }

    this.trigger(errors.length ? 'invalid' : 'valid');
    return errors;
  },
  isPaypalPaymentValidAfterLastAuth: function () {
    var isValid = false;
    // Quarterly recurring contributions do not need reuthorization for amount changes
    if (this.paypalAuthorizedRecurringFrequency === 5 && this.contributionRecurrenceModel.get('frequency') === 5) {
      isValid = true;
    }
    // Reauthorize if switching to or from Quarterly recurring
    else if (this.paypalAuthorizedRecurringFrequency === 5 || this.contributionRecurrenceModel.get('frequency') === 5) {
      isValid = false;
    }
    // The amount is valid if it is less than or equal to 150% of the last authorized amount in the same frequency
    else if (this.paypalAuthorizedRecurring === this.contributionRecurrenceModel.get('isRecurring')
      && this.paypalAuthorizedAmount && this.contributionAmountModel.get('totalAmount') <= this.paypalAuthorizedAmount * 1.5) {
        isValid = true;
    }
    return isValid;
  },
  errorsForPayPal: function () {
    var errors = [];
    var paypalNonce = this.$('input[name="paypalNonce"]').val();

    var requiredError = this.toError(this.resources.PrimaryResources.PayPalRequired);
    var reauthorizeError = this.toError(this.resources.PrimaryResources.PayPalReauthorize);
    var reauthorizeRecurringError = this.toError(this.resources.PrimaryResources.PayPalReauthorizeRecurring);
    if (this.selectedPaymentMethod === 'venmo') {
      requiredError = this.toError(this.resources.PrimaryResources.VenmoRequired);
      // We want to display a different error message if the user is switching to a Quarterly recurring and has already authorized with Venmo
      reauthorizeError = (this.contributionRecurrenceModel.get('frequency') === 5) ? this.toError(this.resources.PrimaryResources.VenmoReauthorizeQuarterly) : this.toError(this.resources.PrimaryResources.VenmoReauthorize);
      reauthorizeRecurringError = (this.contributionRecurrenceModel.get('frequency') === 5) ? this.toError(this.resources.PrimaryResources.VenmoReauthorizeQuarterly) : this.toError(this.resources.PrimaryResources.VenmoReauthorizeRecurring);
    }

    if (!paypalNonce) {
      errors.push(requiredError);
    }
    if (this.paypalExpirationTime && Date.now() > this.paypalExpirationTime) {
      errors.push(reauthorizeError);
    } else if (!this.paypalAuthorizedRecurring && this.contributionRecurrenceModel.get('isRecurring')) {
      errors.push(reauthorizeRecurringError);
    } else if (this.options.formview.paymentMethodConfiguration.payPalIntegrationType === 'PayPalCommercePlatform' && !this.isPaypalPaymentValidAfterLastAuth()) {
      errors.push(reauthorizeError);
    }

    this.trigger(errors.length ? 'invalid' : 'valid');
    return errors;
  },
  errorsForApplePay: function () {
    // TODO: Add more robust error handling in VAN-68666
    var errors = [];

    if (this.selectedPaymentMethod === 'applepay') {
      if (!this.$('input[name="paymentResponseDetails"]').val()) {
        errors.push(this.toError(this.resources.PrimaryResources.ApplePayNotComplete));
      }

      // We ask the user to reauthorize with apple pay if they change the amount or frequency
      // of their contribution, or we think the sesion has expired.
      // Because the upsell workflow doesn't update the actual amount client-side, we check the proposed amount if it exists.
      var expectedAmount = this.contributionAmountModel.get('proposedTotalAmount') || this.contributionAmountModel.get('totalAmount');
      if (this.applePayAuthorizedFrequency !== this.contributionRecurrenceModel.get('frequency')
        || this.applepayAuthorizedAmount !== expectedAmount
        || Date.now() > this.applePayExpirationTime) {
        errors.push(this.toError(this.resources.PrimaryResources.ApplePayReauthorize));
      }
    }

    this.trigger(errors.length ? 'invalid' : 'valid');
    return errors;
  },
  errorsForGooglePay: function () {
    var errors = [];

    if (this.selectedPaymentMethod === 'googlepay') {
      if (!this.$('input[name="confirmationTokenId"]').val()) {
        errors.push(this.toError(this.resources.PrimaryResources.GooglePayNotComplete));
      }

      const tokenExpirationTime = this.googlePayExpirationTime ? new Date(this.googlePayExpirationTime * 1000) : null;

      var expectedAmount = this.contributionAmountModel.get('proposedTotalAmount') || this.contributionAmountModel.get('totalAmount');

      if (this.googlePayAuthorizedFrequency !== this.contributionRecurrenceModel.get('frequency')
        || this.googlePayAuthorizedAmount !== expectedAmount
        || Date.now() > tokenExpirationTime) {
        errors.push(this.toError(this.resources.PrimaryResources.GooglePayReauthorize));
      }
    }

    this.trigger(errors.length ? 'invalid' : 'valid');
    return errors;
  },
  updateSubmitText: function () {
    // Update the submit button merge fields based on the current amount and recurrence state
    var amount = this.contributionAmountModel.get('totalAmount');
    var frequencyDisplay = this.contributionRecurrenceModel.getFrequencyDisplayName();
    var submitText = DoubleBracket.simpleFill(this.originalSubmitLabel, {
      'Amount': currency.format(amount || 0),
      'Frequency': frequencyDisplay
    }).trim();
    submitText = submitText || _.find(this.parent.model.get('form_elements'), { type: 'submit' }).value;
    this.parent.$('input[type="submit"]').val(submitText);
    this.parent.$('button[type="submit"], .submitStep').text(submitText);
  },
  setPayPalDisplay: function (options) {
    options = options || {};

    if (options.paypalNonce) {
      this.$('.at-paypal-amount').text(currency.format(options.amount));
      this.$('.at-paypal-account-info').text(options.email);

      this.paypalAuthorizedRecurring = this.contributionRecurrenceModel.get('isRecurring');
      this.paypalAuthorizedRecurringFrequency = this.contributionRecurrenceModel.get('frequency');
      this.paypalExpirationTime = options.expires;
      this.paypalAuthorizedAmount = options.amount;
      this.paypalFundingSource = options.fundingSource;
    } else {
      this.paypalAuthorizedRecurring = null;
      this.paypalAuthorizedRecurringFrequency = null;
      this.paypalExpirationTime = null;
      this.paypalAuthorizedAmount = null;
      this.paypalFundingSource = null;
    }

    if (this.paypalFundingSource === 'venmo') {
      this.$('.at-paypal-logo-img').attr('src', this.venmoLogoUrl);
      this.$('.at-paypal-logo-img').attr('alt', this.resources.PrimaryResources.Venmo);
      this.$('.at-paypal-logo-img').attr('style', 'width: 100px;');
      this.$('.at-paypal-authorized-text').text(this.resources.PrimaryResources.AuthorizedThroughVenmo);
    } else {
      this.$('.at-paypal-logo-img').attr('src', this.paypalLogoUrl);
      this.$('.at-paypal-logo-img').attr('alt', this.resources.PrimaryResources.PayPal);
      this.$('.at-paypal-logo-img').attr('style', '');
      this.$('.at-paypal-authorized-text').text(this.resources.PrimaryResources.AuthorizedThroughPayPal);
    }

    this.paypalNonce = options.paypalNonce ? options.paypalNonce : null;
    this.paypalAccount = options.email;

    this.$('input[name="paypalNonce"]').val(options.paypalNonce);
    this.$('input[name="payPalNonceType"]').val(options.payPalNonceType);
    this.$('.at-paypal-accepted-here > .at-payment-integration-container').toggle(!!options.paypalNonce);
    this.clearFeedback();
  },
  setApplePayDisplay: function (options) {
    options = options || {};
    if (options.paymentResponseDetails) {
      this.$('.apple_pay_payment_details').val(JSON.stringify(options.paymentResponseDetails));

      // Show apple payment details section
      this.$('.apple_pay_amount').text(currency.format(options.amount));
      this.$('.apple_pay_card_info').text(options.cardInfo);

      this.applepayAuthorizedAmount = options.amount;
      this.applePayAuthorizedFrequency = this.contributionRecurrenceModel.get('frequency');
      this.applePayExpirationTime = options.expires;
    } else {
      this.$('.apple_pay_payment_details').val('');

      this.applepayAuthorizedAmount = null;
      this.applePayAuthorizedFrequency = null;
      this.applePayExpirationTime = null;
    }
    this.$('.at-apple-pay-accepted-here > .at-payment-integration-container').toggle(!!options.paymentResponseDetails);
    this.clearFeedback();
  },
  setGooglePayDisplay: function (options) {
    options = options || {};
    if (options.confirmationTokenId) {
      this.$('.confirmation_token_id').val(options.confirmationTokenId);

      this.$('.google_pay_amount').text(currency.format(options.amount));
      this.$('.google_pay_card_info').text(options.cardInfo);

      this.googlePayAuthorizedAmount = options.amount;
      this.googlePayAuthorizedFrequency = this.contributionRecurrenceModel.get('frequency');
      this.googlePayExpirationTime = options.expires;
    } else {
      this.$('.confirmation_token_id').val('');

      this.googlePayAuthorizedAmount = null;
      this.googlePayAuthorizedFrequency = null;
      this.googlePayExpirationTime = null;
    }
    this.$('.at-google-pay-accepted-here > .at-payment-integration-container').toggle(!!options.confirmationTokenId);
    this.clearFeedback();
  },
  changePaymentMethod: function (data) {
    var newMethod = data.newMethod;

    if (newMethod !== this.selectedPaymentMethod) {
      this.setPaymentInformationDisplay(newMethod);
      this.selectedPaymentMethod = newMethod;

      this.parent.subviews.error_console.clear();
      this.clearFeedback();
      invokeCallbacks('postPaymentMethodChanged', { 'new_payment_method': newMethod });
    }

    // To ensure instant processing occurs after the payment method is changed on this view,
    // we use a different event for after this view has changed its payment method
    if (data.isUserSelection) {
      this.options.formview.trigger('postPaymentMethodChanged', newMethod);
    }

    return this;
  },
  setPaymentInformationDisplay: function (method) {
    this.$('.at-paypal-accepted-here')
      .toggle(method === 'paypal' || method === 'venmo');

    this.$('.at-eft-accepted-here')
      .toggle(method === 'eft');

    this.$('.at-apple-pay-accepted-here')
      .toggle(method === 'applepay');

    this.$('.at-google-pay-accepted-here')
    .toggle(method === 'googlepay');

    var creditCardVisible = (method === 'creditcard');
    this.def.required = creditCardVisible;
    this.$('.cards').toggle(creditCardVisible);
    this.$('.at-cc-number').toggle(creditCardVisible);
    _.each(this.subviews, function (subview) {
      if (subview.name !== 'PaymentMethod') {
        subview.$el.toggle(creditCardVisible);
      }
    });
  },
  clearFeedback: function () {
    FieldsetView.prototype.clearFeedback.call(this);

    this.$('.paypal-label').find('small.' + 'error').remove();
    this.$('.paypal-label').removeClass('error');
    this.$('.apple-pay-label').find('small.' + 'error').remove();
    this.$('.apple-pay-label').removeClass('error');
    this.$('.google-pay-label').find('small.' + 'error').remove();
    this.$('.google-pay-label').removeClass('error');
    this.$('.routing-number-label').find('small.' + 'error').remove();
    this.$('.routing-number-label').removeClass('error');
    this.$('.account-number-label').find('small.' + 'error').remove();
    this.$('.account-number-label').removeClass('error');
    this.$('.confirm-account-number-label').find('small.' + 'error').remove();
    this.$('.confirm-account-number-label').removeClass('error');
    this.$('.authorize-payment-label').removeClass('error');

    return this;
  },
  val: function () {
    var val = {},
      subview_val;


    _.each(this.subviews, function (subview) {
      subview_val = subview.val();

      if (_.isObject(subview_val)) {
        // If it's a dictionary, add it to this dictionary
        _.extend(val, subview_val);
      } else {
        // If just a value, add it to the dictionary, but don't add false-y values
        if (subview_val) {
          val[subview.name] = subview_val;
        }
      }
    });

    if (this.selectedPaymentMethod === 'paypal' || this.selectedPaymentMethod === 'venmo') {
      val.paypalNonce = this.$('input[name="paypalNonce"]').val();
      val.payPalNonceType = this.$('input[name="payPalNonceType"]').val();
    } else if (this.selectedPaymentMethod === 'eft') {
      val.RoutingNumber = this.$('#routingNumber').val();
      val.BankAccountNumber = this.$('#bankAccountNumber').val();
      val.BankAccountType = this.$('#bankAccountType').val();
      val.BankMandateAccepted = this.$('#authorizePayment').is(':checked');
    } else if (this.selectedPaymentMethod === 'applepay') {
      val.PaymentResponseDetails = this.$('input[name="paymentResponseDetails"]').val();
    } else if (this.selectedPaymentMethod === 'googlepay') {
      val.ConfirmationTokenId = this.$('input[name="confirmationTokenId"]').val();
    }

    if (val.Account ||
      val.ExprirationMonth ||
      val.ExprirationYear ||
      val.SecurityCode) {
      this.previousVal = val;
    }

    // send what credit card info we have if we are submitting with saved card
    // dont pass the info if this view is hidden - this only happens with TicketedEvent forms with amount=0
    if (this.readonly && this.selectedPaymentMethod === 'creditcard' && !this.hide) {
      if (this.cc_4_digit) {
        val.CreditCardLastFour = this.cc_4_digit;
      }
      if (this.cc_4_digit) {
        val.CreditCardLastFourType = this.cc_type;
      }
      // setting this flag at all tells FastAction to retrieve the saved card from the card pool
      if (this.fastActionTokenPool) {
        val.FastActionTokenPool = this.fastActionTokenPool;
      }
    }

    return val;
  },
  _onSubviewsRendered: function () {
    this.$('.updateMyProfileSection').show();
    this.cvc = this.$('input[name="SecurityCode"]');
    this.exp = this.$('input[name="ExpirationDate"]');
    if (this.cc_type) {
      this.cardType = 'unknown';
      this.setCardType(null, this.cc_type);
    }
    FastAction.adjustCheckbox(this);

    // When we render this view all subviews are rendered as well.
    // We need call this to avoid showing CC views when a different method is selected
    if (this.selectedPaymentMethod) {
      this.setPaymentInformationDisplay(this.selectedPaymentMethod);
    }

    // ensure the submit button text is processed in case the amount doesn't get immediately changed
    var self = this;
    _.defer(function () {
      self.updateSubmitText();
    });
  },
  setCardType: function (e, cardType) {
    if (e && e.currentTarget.readOnly) {
      return this;
    }
    var previous = this.cardType;
    this.cardType = cardType;
    var card = this.$('.cc-type, .cvc-type');
    if (this.cvc.length) {
      if (previous === 'amex' && cardType !== 'amex') {
        this.cvc.prop('placeholder', '•••')
          .prop('minLength', 3)
          .prop('maxLength', 3);
      } else if (previous !== 'amex' && cardType === 'amex') {
        this.cvc.prop('placeholder', '••••')
          .prop('maxlength', 4)
          .prop('minLength', 4);
      }
    }
    card.removeClass(previous + ' unknown').addClass(cardType);
    return this;
  },
  eftInputFocus: function (e) {
    this.eftInputsFocused[e.currentTarget.id] = e.currentTarget.id;
    if (e.relatedTarget &&
      e.relatedTarget.classList.value.indexOf('btn-at-primary') >= 0 &&
      e.relatedTarget.classList.value.indexOf('nextStep') < 0) {
      // if the submit button has been clicked (ignoring the "Next" button for multi-step forms)
      this.eftSubmitFocused = true;
    }
  },
  eftInputsFocused: {},
  eftSubmitFocused: false,
  eftErrorIds: {
    account: 'eft_account_number',
    routing: 'eft_routing_number',
    accountConfirm: 'eft_account_number_confirm',
    authorize: 'eft_authorize'
  },
  type: 'payment_information'
});
